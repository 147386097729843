.search {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 rgb(0 0 0 / 0%);
    width: 100%;
}
.searchInputs {
  display: flex;
  align-items: center;
  padding: 0 15px 0 0;
  width: 100%;
  height: auto;
  border-radius: 5px;
  background-color: white;

  gap: 24px;

}

.searchInputs input {
  border: 0;
  outline: 0;
  background: none;
  font-size: 18px;
  width: 100%;
}

.dataResult {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 5;
  overflow: hidden;
  background: #fff;
  border-radius: 0 0 24px 24px;
  box-shadow: 0 4px 6px rgb(32 33 36 / 28%);
  border: 0;

}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataItem {
  padding: 1px 2px;
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
}

.dataItem:hover {
  background-color: #e9e9e9;
}

span.input-group-text {
  height: 38px;
}

p.pdataItem {
  display: contents;
}