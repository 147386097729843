input[type="search"] {
  height: 19px;

}

textarea#txtEmail {
  min-height: 158px;
}

div#panelActions {
  margin-top: 5px;
  left: 0.1%;
  width: 99.9%;
}

div#panelEmail {
  margin-top: 5px;
  left: 0.1%;
  width: 99.9%;
  ;
}

div#panelQuest {
  margin-top: 5px;
  left: 0.1%;
  width: 99.9%;
}

.card.direct-chat.direct-chat-primary.cardAddBgg {
  position: relative;
  left: 10%;
  top: 10px;
  width: 80%;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle {
  background: none;
  color: #0012ff;
  font-weight: 700;
  font-size: 18px;
}

.tabulator .tabulator-header .tabulator-col {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border-right: 1px solid #aaa;
  background: #343b4100;
  text-align: left;
  vertical-align: bottom;
  overflow: hidden;
}

.tabulator-headers {
  max-height: 50px;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable .tabulator-col-title {
  padding-right: 15px;
}

.container-login100-form-btn {
  padding-top: 25px;
}

.example-table-theme {
  background-color: #ccc;
  border: 1px solid #333;
  border-radius: 10px;
}

.example-table-theme.tabulator {
  background-color: #ccc;
  border: 1px solid #333;
  border-radius: 10px;
}

/*Theme the header*/
#example-table-theme .tabulator-header {
  background-color: #333;
  color: #fff;
}

p#labelboleto {
  line-height: 0px;
}

/*Allow column header names to wrap lines*/
#example-table-theme .tabulator-header .tabulator-col,
#example-table-theme .tabulator-header .tabulator-col-row-handle {
  white-space: normal;
}

/*Color the table rows*/
#example-table-theme .tabulator-tableHolder .tabulator-table .tabulator-row {
  color: #fff;
  background-color: #666;
}

/*Color even rows*/
#example-table-theme .tabulator-tableHolder .tabulator-table .tabulator-row:nth-child(even) {
  background-color: #444;
}

.react-grid-HeaderCell {
  padding: 4px;
}

div#divcalcular {
  border: ridge;
  padding-left: inherit;
  margin-left: auto;
  display: block;
}

button#btnCloseQuest {
  margin-top: 10px;
}

label#labelTCalc {
  background: aliceblue;
  width: -webkit-fill-available;
  text-align: center;
}

form#formRespQuestion {
  font-size: small;
}

input#inputLucro {
  background: darkgreen;
  color: white;
  font-style: italic;
  font-family: cursive;
  text-align: center;
}

button#btnGerarProp {
  margin-top: 10px;
}

.load {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 250px;
  left: 40%;
  color: #2daabf;
  z-index: 100;
}

.card-body.locCont {
  /* flex: unset; */
  padding: 0;
}

.card-header.ui-sortable-handle.gertitle.locCont {
  background: #353b42;
}

.react-confirm-alert-overlay {
  background: rgb(0 0 0 / 50%) !important;
}

.react-confirm-alert {
  max-width: 80%;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 50%);
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  background-color: #fff;
}

p#pmodal {
  text-align: center;
}

a.abgg:hover {
  color: #ff0000;
}

a.abgg {
  font-weight: bold;
  font-family: sans-serif;
  font-style: oblique;
}

button#btnListarProp {
  margin-top: 10px;
}

label#labelTipoG {
  color: black;
  display: contents;
}

label#labelTipoP {
  color: black;
  display: contents;
}

label#labelTipoI {
  color: black;
  display: contents;
}

input#inputVTotal {
  background: #0c69ab;
  color: white;
  font-style: italic;
  font-family: cursive;
  text-align: center;
}

input#txtRetira {
  float: left;
}

.info-box-content-bgg {
  padding: 20px 10px;
  flex: 1;
}

h1.box-title-bgg {
  display: flex;
  padding: 10px 10px 10px 18px;
  font-size: inherit;
}

.btn.btn-info.btn-block.btnbggp {
  margin-bottom: 25px;
  margin-top: -10px;
}

p.bgg_p_lav {
  margin: 0px 0px 0px 0px;
  font-size: 15px;
  text-align: center;
}

p.bgg_p_lav_dt {
  margin: 0px 0px 0px 0px;
  font-size: 16px;
  color: red;
  text-align: center;
}

p.bgg_p_lav_t {
  margin: 0px 0px 4px 0px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
}

.rbc-events-container {
  display: grid;
  grid-template-rows: repeat(24, 40px);
}

.rbc-day-slot .rbc-event {
  position: static;
}

.rbc-day-slot .rbc-event--global {
  background-color: #c2820d !important;
}

.rbc-day-slot .rbc-event--regional {
  background-color: #f73495 !important;
}

.rbc-day-slot .rbc-event--aguardando {
  background-color: #fbab18 !important;
}

.rbc-day-slot .rbc-event--aguardandodmais {
  background-color: #ff0303 !important;
}

.rbc-day-slot .rbc-event--inicial {
  background-color: #03b4fa !important;
}

.rbc-day-slot .rbc-event--atrasado {
  background-color: #707070 !important;
}

.rbc-day-slot .rbc-event--faltou {
  background-color: #cbcbcb !important;
}

.rbc-day-slot .rbc-event--cancelado {
  background-color: #cbcbcb !important;
}

.rbc-day-slot .rbc-event--atendimento {
  background-color: #283aff !important;
}

.rbc-day-slot .rbc-event--finalizado {
  background-color: #008d23 !important;
}

.rbc-day-slot .rbc-event--bloqueado {
  background-color: #d2d2d2 !important;
}

.rbc-day-slot .rbc-event--espera {
  background-color: #8d8d8d !important;
}

.rbc-event.rbc-event--espera {
  background-color: #8d8d8d !important;
}

.rbc-time-gutter.rbc-time-column {
  display: block !important;
  z-index: 9 !important;
}

.rbc-row.rbc-row-resource {
  font-size: 19px !important;
  background-color: lightyellow;
  min-height: 40px !important;
  line-height: 40px;
}

.nav-link.active {
  background-color: #00085a !important;
}

.rbc-day-bg.rbc-today {
  background-color: #e2efff !important;
}

button#btnCliChegou {
  background-color: #fbab18;
}

button#btnCliInitAte {
  background-color: #283aff;
}

button#btnCliFinalizar {
  background-color: #008d23;
}

button#btnCancelamento {
  margin-right: 0.5em;
  margin-left: 0.5em;
}

button#btnWhatsApp {
  margin-left: 0.5em !important;
  margin-right: 0.5em !important;
  background-color: limegreen;
}

button#btnWhatsConf {
  /* margin-left: 0.2em !important; */
  margin-right: 0.5em !important;
  background-color: limegreen;
}

.rbc-event-label {
  display: none;
}

.rbc-event-label {
  display: none !important;
}

p#eventlabelTitle {
  padding: 0px 0px 0px 0px !important;
  margin: 0px 0px 0px 0px !important;
  font-size: 0.9em !important;
  color: #fff7a5!important;
  font-weight: 400!important;
}
p#eventlabelTitleAgenda {
  padding: 0px 0px 0px 0px !important;
  margin: 0px 0px 0px 0px !important;
  font-size: 0.9em !important;
  color: #24049b!important;
  font-weight: 400!important;
}

p#eventlabelServices {
  padding: 0px 0px 0px 0px !important;
  margin: 5px 0px 0px 10px !important;
  font-size: 0.9em !important;
}
p#eventlabelID {
  padding: 0px 0px 0px 0px !important;
  margin: 5px 0px 0px 10px !important;
  font-size: 0.7em !important;
  font-style: italic;
}

a#tab-agenda-reserva {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

#tab-agenda-reserva {
  background-color: #4bb1c2;
  border-bottom: 1px solid #00125a;
  border-top: 1px solid #327580;
  border-left: 1px solid #327580;
  border-right: 1px solid #327580;
}

a#tab-agenda-lista {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

a#tab-agenda-bloquear {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

#tab-agenda-lista {
  background-color: #4bb1c2;
  border-bottom: 1px solid #00125a;
  border-top: 1px solid #327580;
  border-left: 1px solid #327580;
  border-right: 1px solid #327580;
}

#tab-agenda-bloquear {
  background-color: #4bb1c2;
  border-bottom: 1px solid #00125a;
  border-top: 1px solid #327580;
  border-left: 1px solid #327580;
  border-right: 1px solid #327580;
}

div#tabreserva {
  box-shadow: 0 0 10px rgb(243 16 16 / 10%), 0 1px 8px rgb(0 18 90 / 62%) !important;
}

div#byuser {
  font-size: 0.8em;
  padding: 0px 22px;
}

p#eventlabelNoServices {
  padding: 0px 0px 0px 0px !important;
  margin: 5px 0px 0px 10px !important;
  font-size: 0.9em !important;
  color: yellow;
}

button#btnGravarClose {
  background-color: red;
}

button#btnEnviar {
  background-color: green;
}

button#btnGravar {
  background-color: green;
}

.btnselected {
  background-color: #caff9e !important;
}


@media (min-width: 992px) {


  nav.main-header.navbar.navbar-expand.navbar-white.navbar-light.border-bottom {
    height: 40px;
  }

  img.brand-image.img-circle.elevation-3 {
    width: 32px;
  }

  section.content-header {
    padding: 0px 0.5rem !important;
  }

  .content-wrapper {
    margin-top: 42px !important;
    margin-left: 226px;
  }


  span.brand-text.font-weight-light {
    font-size: 17px;

    display: block;
  }
}

label#labelSelecionados {
  margin-left: 65%;
}

button#btnCancelamento {
  background-color: red;
}

div#divReactTabulator {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 2em;
}

div#r1 {
  box-shadow: 0 0 10px rgb(0 0 0 / 13%), 0 4px 7px rgb(0 0 0 / 20%);
  font-size: 20px;
}

button#btnFinalizarAgenda {
  margin-left: 0.5em;
  margin-right: 0.5em;
  background-color: #2eac2e;
}

div#btnsFinalizar {
  margin-top: 2em;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.btn:disabled {
  opacity: 0.15!important;
  box-shadow: none!important;
}
img#imgloadingReactTabulator {
  width: 5em;
  margin-top: 2em;
  margin-inline: auto;
}
img#imgloadingReactTabulatorPgto {
  width: 5em;
  margin-top: 2em;
  margin-inline: auto;
}
div#SelProdInsumo {
  margin: auto;
  padding-top: 2em;
}
input#inputTotalInsumos {
  background-color: darkgreen!important;
  color: white;
  font-size: 1.2em;
  width: 6em;
  align-items: center;
  text-align: center;
}
div#SelectServDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 0px;
}
button#btnCliNaoFaltou {
  background-color: #fb1818;
}
div#pagamento {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: -2em;
}
label#labelTCalcPgto {
  background: #09ff0247;
  width: -webkit-fill-available;
  text-align: center;
}
button#btnReset {
  background-color: #fb1818;
}
button#btnAlterar {
  margin-right: 0.5em !important;
  background-color: #3242cd;
}