@media only screen and (max-width: 600px) {
  .content-wrapper {
    margin-top: 2em !important;
  }

  nav.main-header.navbar.navbar-expand.navbar-white.navbar-light.border-bottom {
    height: 2em !important;
  }
}

@media only screen and (max-width: 360px) {
  span.brand-text.font-weight-light {
    font-size: initial;
  }
}

@media only screen and (max-width: 320px) {
  span.brand-text.font-weight-light {
    font-size: x-small;
  }
}


button#btnAlterarFinalizar {
  background-color: green;
  margin-right: 1em;
}

li#headerlogo {
  margin-top: 6px;
  margin-right: 0.4em;
}

span.spanobs {
  font-size: 0.7em;
  color: aquamarine;
  float: left;
}